li {
  color: var(--black);
}

.portfolio .portfolioList {
  justify-self: center;
  max-width: 400px;
  margin: auto;
}
.portfolio .portfolioList Accordion {
  color: red;
}
