.logo {
  display: block;
  /* margin-top: 10px; */
  margin-bottom: 1vh;
  /* max-width: 15%; */
  width: 100%;
  max-height: 80px;
  object-fit: contain;
  /* min-width: 20%; */
}
.logo:hover {
  opacity: 0.85;
}

.App-header {
  background-color: black;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  justify-content: center;
  border-bottom: 2px solid var(--comp2);
  max-height: 90px;
}
.header-block {
  display: grid;
  width: 100%;
  /* height: 20px; */
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  /* max-width: 1500px; */
  align-items: bottom;
  margin-top: 1vh;
  position: relative;
}
/* .menu-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
} */
a {
  display: flex;
  text-decoration: none;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}
.App-header h3 {
  color: var(--bg);
  font-size: 1.2rem;
  margin: 10px 10px;
  padding-bottom: 1vh;
  align-self: flex-end;
  justify-self: center;
  opacity: 1;
  cursor: pointer;
  font-weight: 150;
}
img {
  width: 100%;
  max-width: 400px;
  margin: 0px;
  padding: 0px;
  /* margin: 5% auto;
  padding: 5px;
  object-fit: cover; */
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: var(--comp1);
}
