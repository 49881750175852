:root {
  --gradient: linear-gradient(
    55deg,
    #252422,
    #403d39,
    #003554,
    #22577a,
    #38a3a5,
    #57cc99,
    #80ed99
  );
  --bg: #faf9f9;
  --comp0: #f0eeea;
  --comp1: #ccc5b9;
  --comp2: #403d39;
  --comp3: #006494;
  --black: #252422;
  --accent: #eb5e28;
}

html {
  scroll-padding-top: 90px;
  width: 100%;
}
.App {
  background-color: var(--bg);
  min-height: 180vh;
  max-width: 100%;
  font-size: 1.5em;
}

.App-link {
  color: var(--bg);
}

@media screen and (max-width: 1000px) {
  .split {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
  }
  .outerblock {
    width: 90%;
    margin: auto;
  }
  .header-block {
    width: 90%;
  }
}
@media screen and (min-width: 1000px) {
  .split {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: 6fr 6fr;
    margin: 15px auto;
  }
  .outerblock {
    width: 70%;
    max-width: 1000px;
    margin: auto;
  }
  .header-block {
    width: 70%;
  }
}

p,
ul,
li,
h2,
h3 {
  font-family: "Plantin Light", serif;
  font-weight: lighter;
  color: var(--black);
  font-style: normal;
  font-size: 1rem;
  opacity: 0.52;
  line-height: 1.5;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.85em;
  letter-spacing: 0.05em;
}
li {
  opacity: 1.5 !important;
}
h1 {
  font-family: "Plantin Light", serif;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.025em;
  opacity: 0.8;
}
b {
  color: var(--comp3);
  font-weight: 1000;
}
.CTA {
  margin: auto;
  text-align: center;
}
