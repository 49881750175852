.title {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  display: inline-block;
  justify-content: center;
  position: absolute;
  text-transform: uppercase;
  font-weight: 600;
  color: #ee6352;
  position: abosolute;
  background-image: var(--gradient);
  background-clip: text;
  text-align: center;
  -webkit-box-decoration-break: clone;
  color: rgb(255, 255, 255, 0);
  background-size: 650%;
  animation: gradient-animation 4s infinite alternate;
}
.logoSplash {
  min-height: 100vh;
}
.Z {
  text-align: center;
  margin: auto;
  font-size: 45rem;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  width: 100%;
  height: 50%;
  z-index: 1;
}
@media screen and (max-width: 500px) {
  .Z {
    font-size: 30rem;
    padding: 20px 0;
  }
}
.box {
  position: absolute;
  top: 40%;
  left: 0%;
  width: 65%;
  background-color: var(--bg);
  border: solid var(--bg) 3.2rem;
  /* border: solid red 3rem; */
  z-index: 2;
}
.innerLogo {
  font-size: 5rem;
  position: absolute;
  letter-spacing: 0.15em;
  top: 40.5%;
  left: 0%;
  z-index: 3;
}
.slogan {
  max-width: 700px;
  justify-self: center;
  margin: auto;
}
.LogoSplash h2 {
  font-size: 1.5rem;
  /* transform: translate(25%); */
  justify-content: center;
  text-align: center;
  font-style: italic;
  margin-top: -115px;
  margin-bottom: 115px;
  font-weight: normal;
}
@keyframes gradient-animation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}
