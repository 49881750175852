h1 {
  color: var(--black);
  text-align: center;
}
.wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.form {
  width: 100%;
  max-width: 900px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

input {
  width: 100%;
  max-width: 900px;
  flex-grow: 1;
  padding: 12px 20px;
  margin: 4px auto;
  border: 1px solid #ccc;
  background-color: var(--comp0);
  color: var(--black);
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  flex-grow: 1;
  max-width: 900px;
  padding: 12px 20px;
  margin: 4px auto;
  border: 1px solid #ccc;
  background-color: var(--comp0);
  color: var(--black);
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
}
button,
.submit {
  width: 100%;
  flex-grow: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 900px;
  padding: 12px 25px;
  background-color: var(--comp2);
  color: var(--bg);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
.submit:hover {
  background-color: var(--comp3);
}
.noHover {
  pointer-events: none;
}
.error {
  text-align: center;
  color: var(--accent);
  /* border: none;
  margin: auto;
  flex-grow: 1; */
  width: 100%;
  max-width: 900px;
  /* margin: 0; */
  /* padding: 0; */
  font-size: 1.1rem;
}
.success {
  text-align: center;
  color: var(--black);
  margin: 5px 0;
  padding: 0;
  font-size: 1.1rem;
}
.captcha {
  flex-grow: 0;
  /* width: 100%; */
  flex-basis: auto;
  justify-self: center;
}

.calendly-wrapper {
}

.setAppointment {
}

@media screen and (max-width: 743px) {
  .calendly-wrapper {
    margin-top: 75px;
  }
}

/* Footer */
.footer {
  position: relative;
  padding: 10px;
  margin: auto;
  left: 0;
  justify-content: center;
  bottom: 0;
  width: 90%;
  color: var(--comp1);
  text-align: center;
  font-size: 1rem;
}
.footer p {
}
